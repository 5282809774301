import { useLazyQuery } from '@apollo/client';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import throttle from 'lodash/throttle';
import { MouseEvent, useEffect, useRef, useState } from 'react';

import BookmarkListModalFooter from './BookmarkListModalFooter/BookmarkListModalFooter';
import BookmarkListModalItem from './BookmarkListModalItem/BookmarkListModalItem';
import { CreateNewCollectionModalItem } from './CreateNewCollectionModalItem/CreateNewCollectionModalItem';
import TextButton from 'components/Buttons/TextButton/TextButton';
import Modal, { ModalProps } from 'components/Modals/Modal/Modal';

import Logger from 'lib/utils/Logger';

import { SelectedOption } from 'data/graphql/types';
import {
  BookmarkList,
  CreateBookmarkOptions,
  DeleteBookmarkOptions,
} from 'types/api';
import { GetBookmarkListsDocument } from 'types/generated/api';

import CloseIcon from 'assets/icons/ic-close.inline.svg';

import styles from './BookmarkListsModal.module.scss';

import { GET_BOOKMARK_LISTS_MODAL_QUERY } from '../BookmarkLists.gql';
import CreateBookmarkListModal from '../CreateBookmarkListModal/CreateBookmarkListModal';

const SCROLL_THROTTLE_RATE = 100;

type BookmarkListsModalProps = {
  createBookmark: (options: CreateBookmarkOptions) => void;
  deleteBookmark: (options: DeleteBookmarkOptions) => void;
  productSid: string;
  selectedOptions: SelectedOption[];
} & Omit<ModalProps, 'children'>;

const SCROLL_CONTAINER_ID = 'bookmarkListContainer';

const BookmarkListsModal = ({
  isOpen,
  createBookmark,
  onCloseClick,
  deleteBookmark,
  productSid,
  selectedOptions = [],
}: BookmarkListsModalProps) => {
  // Sanitize selectedOptions to get rid of extra fields.
  selectedOptions = selectedOptions.map(option => {
    return {
      name: option.name,
      value: option.value,
    };
  });

  const bookmarksListRef = useRef<HTMLDivElement>(null);

  const [hasScrolled, setHasScrolled] = useState(false);
  const [
    showCreateBookmarkListModal,
    setShowCreateBookmarkListModal,
  ] = useState(false);

  useEffect(() => {
    if (bookmarksListRef && bookmarksListRef.current) {
      const bookmarksListRefCurrent = bookmarksListRef.current;
      bookmarksListRefCurrent.addEventListener('scroll', updateHasScrolled);

      return () => {
        bookmarksListRefCurrent.removeEventListener(
          'scroll',
          updateHasScrolled
        );
      };
    }
  });

  const updateHasScrolled = throttle(event => {
    const scrollTop = event?.target?.scrollTop;
    if (scrollTop > 0 && !hasScrolled) {
      setHasScrolled(true);
    } else if (scrollTop === 0 && hasScrolled) {
      setHasScrolled(false);
    }
  }, SCROLL_THROTTLE_RATE);

  const [
    handleGetBookmarkListsForModal,
    { data, error: loadBookmarksError, loading },
  ] = useLazyQuery(GET_BOOKMARK_LISTS_MODAL_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        productSid,
        selectedOptions,
      },
    },
  });

  useEffect(() => {
    if (isOpen) {
      handleGetBookmarkListsForModal();
    }
  }, [isOpen, handleGetBookmarkListsForModal]);

  if (loadBookmarksError) {
    const graphQlErrorMessage = loadBookmarksError?.graphQLErrors[0]?.message;
    // if user is not authenticated, we expect an error of 'Unauthenticated'
    if (graphQlErrorMessage !== 'Unauthenticated') {
      Logger.error(
        `Something went wrong getting bookmark lists: ${graphQlErrorMessage}`,
        loadBookmarksError
      );
    }
  }

  const bookmarkLists: BookmarkList[] = data?.me?.bookmarkLists?.items || [];

  const renderContent = () => {
    if (loading && bookmarkLists.length === 0) {
      return [1, 2, 3].map(item => (
        <BookmarkListModalItem
          bookmarks={{ items: [], pagination: { totalCount: 0 } }}
          createBookmark={createBookmark}
          createdAt=""
          deleteBookmark={deleteBookmark}
          id=""
          key={item}
          loadingSkeleton
          name=""
          productSid={productSid}
          public
          selectedOptions={selectedOptions}
        />
      ));
    }

    return (
      <>
        <CreateNewCollectionModalItem
          onCreateNewCollection={() => {
            setShowCreateBookmarkListModal(true);
          }}
        />
        <CreateBookmarkListModal
          isOpen={showCreateBookmarkListModal}
          onCloseClick={() => {
            setShowCreateBookmarkListModal(false);
          }}
          onCreateBookmarkList={bookmarkListId => {
            const createBookmarkOptions = {
              awaitRefetchQueries: true,
              refetchQueries: [
                {
                  query: GetBookmarkListsDocument,
                },
                {
                  query: GET_BOOKMARK_LISTS_MODAL_QUERY,
                  variables: {
                    filter: {
                      productSid,
                      selectedOptions,
                    },
                  },
                },
              ],
              variables: {
                input: { bookmarkListId, productSid, selectedOptions },
              },
            };
            createBookmark(createBookmarkOptions);
          }}
        />
        {!isEmpty(bookmarkLists) &&
          bookmarkLists.map(bookmarkList => (
            <BookmarkListModalItem
              {...bookmarkList}
              createBookmark={createBookmark}
              deleteBookmark={deleteBookmark}
              isPublic={bookmarkList.public}
              key={bookmarkList.id}
              productSid={productSid}
              selectedOptions={selectedOptions}
            />
          ))}
      </>
    );
  };

  return (
    <Modal
      className={styles.rootModal}
      hideDefaultHeader
      isOpen={isOpen}
      onCloseClick={onCloseClick}
      overlayClassName={styles.overlayStyling}
    >
      {/*
        This non-interactive static element needs a click event because
        the click event will bubble up through the DOM and click on a PLP
        card item, which will suddenly take the user to a PDP page. This
        non-interactive and non-intrusive function prevents this from happening
      */}
      {/* eslint-disable jsx-a11y/click-events-have-key-events  */}
      {/* eslint-disable jsx-a11y/no-static-element-interactions */}
      <div
        onClick={(e: MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
        }}
      >
        {/* eslint-enable jsx-a11y/click-events-have-key-events  */}
        {/* eslint-enable jsx-a11y/no-static-element-interactions */}
        <div
          className={classnames(styles.header, {
            [styles.withBorder]: hasScrolled,
          })}
        >
          <div className={styles.headerText}>Add to Collection</div>
          <TextButton onClick={onCloseClick}>
            <CloseIcon className={styles.closeIcon} />
          </TextButton>
        </div>
        <div
          className={styles.bookmarkListContainer}
          id={SCROLL_CONTAINER_ID}
          ref={bookmarksListRef}
        >
          {renderContent()}
        </div>
        <BookmarkListModalFooter
          onDoneClick={onCloseClick}
          showTopBorder={hasScrolled}
        />
      </div>
    </Modal>
  );
};

export default BookmarkListsModal;
