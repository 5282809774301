/* eslint-disable jsx-a11y/role-supports-aria-props */
import { FetchResult, useMutation } from '@apollo/client';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import {
  MouseEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { getBookmarksBySelectedOptions } from './utils';
import ButtonBase from 'components/Buttons/ButtonBase';
import { BUTTON_SIZE } from 'components/Buttons/ButtonBase';
import AuthModal from 'components/Modals/AuthModal/AuthModal';
import BookmarkAlertModal from 'components/Modals/BookmarkAlertModal/BookmarkAlertModal';

import { AnalyticsSource } from 'lib/analytics/events';
import {
  ProductAddedOrRemovedFromWishlistProperties,
  trackProductAddedToWishlist,
  trackProductRemovedFromWishlist,
} from 'lib/analytics/wishlist';
import auth from 'lib/auth';
import Logger from 'lib/utils/Logger';

import {
  Bookmark,
  CREATE_BOOKMARK_MUTATION,
  CreateBookmarkResponse,
  DELETE_BOOKMARK_MUTATION,
  DeleteBookmarkResponse,
} from './BookmarkButton.gql';
import { GET_BOOKMARKS_BY_PRODUCT_SIDS } from 'containers/ProductDetailPage/ProductDetailPageV2.gql';

import { ProductOption, SelectedOption } from 'data/graphql/types';
import {
  CreateBookmarkOptions,
  CreateBookmarkVariables,
  DeleteBookmarkOptions,
} from 'types/api';
import { BookmarkByProductSid } from 'types/app';
import {
  DeleteBookmarkFromBookmarkButtonMutationVariables,
  GetBookmarkListsDocument,
} from 'types/generated/api';

import BookmarkIconDisabled from 'assets/icons/ic-bookmark-false.inline.svg';
import BookmarkIconEnabled from 'assets/icons/ic-bookmark-true.inline.svg';

import styles from './BookmarkButton.module.scss';

import BookmarkListsModal from '../BookmarkLists/BookmarkListsModal/BookmarkListsModal';

type BookmarkProductData = {
  brand: string;
  categories: string[];
  productOptions?: ProductOption[];
  productSid: string;
  title: string;
};

export type BookmarkButtonProps = {
  bookmarksByProductSid?: BookmarkByProductSid[] | null;
  classNameRoot?: string;
  hasLabel?: boolean;
  isIcon?: boolean; // Makes the button appear as an svg, with an optional loading state
  isLight?: boolean;
  price?: number;
  productCardIndex?: number;
  productData: BookmarkProductData;
  selectedOptions?: SelectedOption[];
  variantSid?: string | null;
};

type CurrentBookmark = {
  id: string;
  selectedOptions: SelectedOption[];
};

const LABEL_BUTTON = 'Bookmark Button';

const BookmarkButton = ({
  bookmarksByProductSid,
  classNameRoot,
  hasLabel,
  isIcon,
  isLight,
  price,
  productCardIndex,
  productData,
  selectedOptions = [],
  variantSid,
}: BookmarkButtonProps): ReactElement => {
  const { productOptions, productSid } = productData;
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const closeAuthModal = useCallback(() => setIsAuthModalOpen(false), []);
  const openAuthModal = useCallback(() => setIsAuthModalOpen(true), []);

  const [
    currentBookmarksByProductSid,
    setCurrentBookmarksByProductSid,
  ] = useState<Array<BookmarkByProductSid | Bookmark>>([]);
  const [currentBookmark, setCurrentBookmark] = useState<
    CurrentBookmark | null | undefined
  >(null);
  const [
    currentBookmarksByProductSidHasBeenSet,
    setCurrentBookmarksByProductSidHasBeenSet,
  ] = useState(false);
  const [isBookmarkListsModalOpen, setIsBookmarkListsModalOpen] = useState(
    false
  );
  const [bookmarkAlertModalState, setBookmarkAlertModalState] = useState({
    isBookmarked: true,
    isOpen: false,
  });

  // Initialize bookmarksByProductSid when loaded, then rely on this array
  // when users add/remove bookmarks, removing a needless query of updated information.
  useEffect(() => {
    if (
      !currentBookmarksByProductSidHasBeenSet &&
      bookmarksByProductSid &&
      !isEmpty(bookmarksByProductSid)
    ) {
      setCurrentBookmarksByProductSidHasBeenSet(true);
      setCurrentBookmarksByProductSid(bookmarksByProductSid);
    }
  }, [bookmarksByProductSid, currentBookmarksByProductSidHasBeenSet]);

  // Updates the bookmarkId state (enabled/disabled), which is determined by comparing
  // the current selectedOptions and the bookmarkSelectedOptions
  useEffect(() => {
    let matchedBookmark = null;
    const matchedBookmarks = getBookmarksBySelectedOptions(
      currentBookmarksByProductSid,
      selectedOptions,
      productOptions
    );

    if (!isEmpty(matchedBookmarks)) {
      matchedBookmark = matchedBookmarks[0];
    }
    // this sets the current bookmark, notice it is not an array!
    setCurrentBookmark(matchedBookmark);
  }, [currentBookmarksByProductSid, productOptions, selectedOptions]);

  const [
    createBookmark,
    {
      data: createBookmarkData,
      error: createBookmarkError,
      loading: createBookmarkLoading,
    },
  ] = useMutation<CreateBookmarkResponse, CreateBookmarkVariables>(
    CREATE_BOOKMARK_MUTATION
  );

  const [
    deleteBookmark,
    { error: deleteBookmarkError, loading: deleteBookmarkLoading },
  ] = useMutation<
    DeleteBookmarkResponse,
    DeleteBookmarkFromBookmarkButtonMutationVariables
  >(DELETE_BOOKMARK_MUTATION);

  const isLoading = createBookmarkLoading || deleteBookmarkLoading;

  // Handles toggling the bookmark. If there is one or bookmark saved
  // show modal, otherwise create bookmark.
  const handleTogglingBookmark = async () => {
    if (currentBookmark) {
      setIsBookmarkListsModalOpen(true);
      return;
    }

    const refetchQueries = [
      {
        query: GET_BOOKMARKS_BY_PRODUCT_SIDS,
        variables: { sids: [productSid] },
      },
      {
        query: GetBookmarkListsDocument,
      },
    ];

    const options = {
      awaitRefetchQueries: true,
      refetchQueries,
      variables: { input: { productSid, selectedOptions } },
    };
    handleCreateBookmark(options);
  };

  const buildAnalyticsData = (
    bookmark: Bookmark
  ): ProductAddedOrRemovedFromWishlistProperties => ({
    brand: productData.brand,
    category: productData.categories[0],
    name: productData.title,
    position: productCardIndex,
    price,
    product_id: productData.productSid,
    variant_id: variantSid,
    wishlist_id: bookmark.bookmarkList.id,
    wishlist_name: bookmark.bookmarkList.name,
  });

  const filterAndSetCurrentBookmarks = (
    bookmarkId?: string
  ): Bookmark | undefined => {
    let removedBookmark: Bookmark | undefined;

    const filteredBookmarks = currentBookmarksByProductSid.filter(bookmark => {
      const shouldKeepBookmark = bookmark.id !== bookmarkId;
      if (!shouldKeepBookmark) {
        removedBookmark = bookmark;
      }
      return shouldKeepBookmark;
    });

    setCurrentBookmarksByProductSid(filteredBookmarks);

    return removedBookmark;
  };

  const handleDeleteBookmark = async (
    mutationOptions: DeleteBookmarkOptions
  ) => {
    try {
      await deleteBookmark(mutationOptions);
      const bookmarkId = mutationOptions.variables.id;
      const removedBookmark = filterAndSetCurrentBookmarks(bookmarkId);
      setCurrentBookmark(undefined);
      setBookmarkAlertModalState({
        isBookmarked: false,
        isOpen: true,
      });
      if (removedBookmark) {
        const analyticsData = buildAnalyticsData(removedBookmark);
        trackProductRemovedFromWishlist(analyticsData);
      }
    } catch (error) {
      Logger.error('Error deleting a bookmark', error);
    }
  };

  const handleCreateBookmark = async (
    mutationOptions: CreateBookmarkOptions
  ) => {
    try {
      const bookmarkResult = await createBookmark(mutationOptions);
      const newBookmark = getBookmarkFromResponseOrThrowError(bookmarkResult);
      const updatedBookmarksByProductSid = currentBookmarksByProductSid.concat(
        newBookmark
      );
      setCurrentBookmarksByProductSid(updatedBookmarksByProductSid);
      setCurrentBookmark(newBookmark);
      setBookmarkAlertModalState({
        isBookmarked: true,
        isOpen: true,
      });
      const analyticsData = buildAnalyticsData(newBookmark);
      trackProductAddedToWishlist(analyticsData);
    } catch (error) {
      Logger.error('Error creating a bookmark', error);
    }
  };

  const handleOnAuthComplete = () => {
    closeAuthModal();
    handleTogglingBookmark();
  };

  const handleOnBookmarkButtonClick = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      await auth.currentAuthenticatedUser();
      handleTogglingBookmark();
    } catch (error) {
      openAuthModal();
    }
  };

  const handleCloseAlertModal = useCallback(() => {
    setBookmarkAlertModalState({
      isBookmarked: bookmarkAlertModalState.isBookmarked,
      isOpen: false,
    });
  }, [bookmarkAlertModalState]);

  const toggleIsBookmarkListsModalOpen = useCallback(
    (e: MouseEvent<HTMLOrSVGElement>): void => {
      setIsBookmarkListsModalOpen(!isBookmarkListsModalOpen);
      e.stopPropagation();
    },
    [isBookmarkListsModalOpen]
  );

  const renderButtonContent = () => {
    if (isLoading) {
      return '';
    }

    return (
      <>
        {currentBookmark ? (
          <BookmarkIconEnabled className={styles.icon} />
        ) : (
          <BookmarkIconDisabled className={styles.icon} />
        )}
        {hasLabel ? <div className={styles.label}>Save</div> : null}
      </>
    );
  };

  const bookmarkError = createBookmarkError || deleteBookmarkError;
  if (bookmarkError) {
    Logger.error('Error toggling a product bookmark', bookmarkError);
  }

  const listTitle =
    createBookmarkData?.createProductBookmark?.bookmark?.bookmarkList?.name ||
    '';
  const buttonClasses = classnames(
    styles.root,
    {
      [styles.isLightIcon]: isLight,
      [styles.isLightSelected]: isLight && !!currentBookmark,
    },
    classNameRoot
  );
  return (
    <>
      <ButtonBase
        aria-label={LABEL_BUTTON}
        aria-pressed={!!currentBookmark}
        className={buttonClasses}
        isIconButton={isIcon}
        isLoading={isLoading}
        isSecondaryButton={!isIcon}
        onClick={handleOnBookmarkButtonClick}
        size={isIcon ? BUTTON_SIZE.SMALL : BUTTON_SIZE.MEDIUM}
      >
        {renderButtonContent()}
      </ButtonBase>

      <BookmarkAlertModal
        closeModal={handleCloseAlertModal}
        isBookmarked={bookmarkAlertModalState.isBookmarked}
        isOpen={bookmarkAlertModalState.isOpen}
        listTitle={listTitle}
        loading={isLoading}
        onChangeClick={toggleIsBookmarkListsModalOpen}
      />
      <AuthModal
        closeModal={closeAuthModal}
        isOpen={isAuthModalOpen}
        onCloseClick={closeAuthModal}
        onSignInSuccess={handleOnAuthComplete}
        onSignUpSuccess={handleOnAuthComplete}
        source={AnalyticsSource.BOOKMARK_BUTTON}
      />
      <BookmarkListsModal
        createBookmark={handleCreateBookmark}
        deleteBookmark={handleDeleteBookmark}
        isOpen={isBookmarkListsModalOpen}
        onCloseClick={toggleIsBookmarkListsModalOpen}
        productSid={productSid}
        selectedOptions={currentBookmark?.selectedOptions || selectedOptions}
      />
    </>
  );
};

const getBookmarkFromResponseOrThrowError = (
  bookmarkResult: FetchResult<CreateBookmarkResponse>
) => {
  const newBookmark = bookmarkResult?.data?.createProductBookmark?.bookmark;

  if (!newBookmark) {
    throw new Error(
      `Not able to extract bookmark from response ${JSON.stringify(
        bookmarkResult
      )}`
    );
  }

  return newBookmark;
};

export default BookmarkButton;
